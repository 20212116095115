// src/App.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Routes, Route } from 'react-router-dom';
import { initGA, logPageView } from './config/analytics';
import ReactGA from 'react-ga4';

// Importing components and pages
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import FAQPage from './pages/FAQPage';
import LoginPage from './pages/LoginPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import Success from './pages/Success';
import WhyUsPage from './pages/WhyUsPage';

// Import StripeProvider
import StripeProvider from './StripeProvider';

function App() {
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
  }, []); // Run only on mount

  useEffect(() => {
    logPageView();
  }, [location]); // Track page view on route change

  // Scroll event tracking
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;

      if (scrollPercentage > 25 && scrollPercentage <= 50) {
        ReactGA.event({
          category: 'Scroll',
          action: 'Scroll_25',
          label: location.pathname,
        });
      } else if (scrollPercentage > 50 && scrollPercentage <= 75) {
        ReactGA.event({
          category: 'Scroll',
          action: 'Scroll_50',
          label: location.pathname,
        });
      } else if (scrollPercentage > 75 && scrollPercentage <= 100) {
        ReactGA.event({
          category: 'Scroll',
          action: 'Scroll_75',
          label: location.pathname,
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  // Outbound link tracking
  useEffect(() => {
    const handleLinkClick = (event) => {
      const target = event.target.closest('a');
      if (target && target.href && !target.href.includes(window.location.hostname)) {
        ReactGA.event({
          category: 'Outbound',
          action: 'Clicked Outbound Link',
          label: target.href,
        });
      }
    };

    document.addEventListener('click', handleLinkClick);

    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, []);

  return (
    <StripeProvider>
      <ScrollToTop /> {/* Scrolls to the top on each route change */}
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/order-details" element={<OrderDetailsPage />} />
        <Route path="/why-us" element={<WhyUsPage />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </StripeProvider>
  );
}

export default App;

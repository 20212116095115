// src/components/SurveyModal.js
import React, { useState } from 'react';

const SurveyModal = ({ isOpen, onClose, onSubmit, headline, question, options }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [otherText, setOtherText] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== 'other') {
      setOtherText(''); // Clear the free-form text if 'Other' is not selected
    }
  };

  const handleCancel = async () => {
    // Send a request to increment the cancel counter
    try {
      await fetch('/api/submit-exit-survey', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action: 'incrementCancelCount' }),
      });
    } catch (error) {
      console.error('Error incrementing cancel count:', error);
    }
    // Close the modal
    onClose();
  };

  const handleSubmit = () => {
    const response = selectedOption === 'other' ? otherText : selectedOption;
    if (response) {
      onSubmit(response);
    }
    onClose();
  };

  return (
    isOpen && (
      <div 
      role="dialog"
      aria-modal="true"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded shadow-lg w-11/12 max-w-md">
          <h2 className="text-xl font-bold mb-4">{headline}</h2>
          <p className="mb-4">{question}</p>
          <form>
            {options.map((option, index) => (
              <div className="mb-2" key={index}>
                <label className="flex items-center">
                  <input
                    type="radio"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                    className="mr-2"
                  />
                  {option.label}
                </label>
                {option.value === 'other' && selectedOption === 'other' && (
                  <textarea
                    value={otherText}
                    onChange={(e) => setOtherText(e.target.value)}
                    className="w-full h-20 p-2 border rounded mt-2"
                    placeholder="Please specify..."
                  ></textarea>
                )}
              </div>
            ))}
          </form>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SurveyModal;

// src/components/PricingCard.js
import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';

const PricingCard = ({
  title,
  price,
  perUnit,
  features,
  buttonText,
  special,
  safetyLevel,
  annualPrice,
  monthlyPrice,
  oneTimePriceId
}) => {
  const stripe = useStripe();
  const [planType, setPlanType] = useState('annual'); // default to annual
  const [subscriptionType, setSubscriptionType] = useState('none'); // Default to 'none'

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Pricing details for the special card
  const prices = {
    annual: {
      amount: '$59',
      perUnit: '/year',
      stripePriceId: process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID,
    },
    monthly: {
      amount: '$5.99',
      perUnit: '/month',
      stripePriceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
    },
  };

  // Subscription options for non-special cards
  const subscriptionOptions = [
    { value: 'none', label: 'None Selected', priceId: null },
    {
      value: 'annual',
      label: `+${annualPrice}`,
      priceId: process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID,
    },
    {
      value: 'monthly',
      label: `+${monthlyPrice}`,
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
    },
  ];

  const couponMapping = {
    'High': {
      annual: 'PP83D9DQ', 
      monthly: null, // No coupon for monthly in High or Very High level
    },
    'Very High': {
      annual: 'A6m2ZJNq',
      monthly: null,
    },
    'Highest': {
      annual: 'jxn9GP8f',
      monthly: 'nkxuqayB',
    },
  };
  

  const handlePlanChange = (e) => {
    setPlanType(e.target.value);
  };

  const handleBuyNowClick = async () => {
    ReactGA.event({
        category: 'Payment',
        action: 'Initiated_Checkout',
        label: 'Stripe Checkout'
      });

    if (special) {
      // Handle the special card (subscription only)
      const payload = {
        planType: planType, // Send 'annual' or 'monthly'
      };

      const response = await fetch('/api/create-subscription-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const session = await response.json();
      if (session.sessionId && stripe) {
        await stripe.redirectToCheckout({ sessionId: session.sessionId });
      } else {
        console.error('Error: No session ID returned from API.');
      }
    } else {
      // Handle non-special cards (mixed checkout with coupons)
      const items = [];

      // Add the one-time product
      if (oneTimePriceId) {
        items.push({
          priceId: oneTimePriceId,
          quantity: 1,
        });
      }

      // Add subscription if selected
      const selectedSubscription = subscriptionOptions.find(
        (option) => option.value === subscriptionType
      );

      if (selectedSubscription && selectedSubscription.priceId) {
        items.push({
          priceId: selectedSubscription.priceId,
          quantity: 1,
        });
      }

      // Determine the coupon ID
      let couponId = null;
      if (subscriptionType !== 'none') {
        const levelCoupons = couponMapping[safetyLevel];
        if (levelCoupons) {
          couponId = levelCoupons[subscriptionType] || null;
        }
      }

      const payload = {
        items: items.filter((item) => item.priceId),
        couponId,
      };

        // Decide which endpoint to call
        let endpointUrl = '';
        if (
        items.length === 1 &&
        (!selectedSubscription || selectedSubscription.priceId === null)
        ) {
        // Only one-time item, use create-checkout-session
        endpointUrl = '/api/create-checkout-session';
        // No couponId needed
        } else {
        // Mixed items or subscription only, use create-mixed-checkout-session
        endpointUrl = '/api/create-mixed-checkout-session';
        // Include couponId if applicable
        payload.couponId = couponId;
        }      

      const response = await fetch(endpointUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const session = await response.json();
      if (session.sessionId && stripe) {
        await stripe.redirectToCheckout({ sessionId: session.sessionId });
      } else {
        console.error('Error: No session ID returned from API.');
      }
    }
  };

  return (
    <div
      className={`mx-auto w-full max-w-xs min-h-[500px] rounded-3xl p-6 text-white shadow-lg flex flex-col ${
        special
          ? 'bg-blue-500 bg-opacity-80 scale-105 transform shadow-2xl'
          : 'bg-gray-800 bg-opacity-60'
      }`}
    >
      <div className="flex-grow">
        <h3 className="truncate text-2xl font-bold">{title}</h3>
        <p className="text-md mb-4 font-semibold">
          {special ? 'SMS Instructions At Every Step' : 'Survivability: '}
          <span
            className={
              safetyLevel === 'High'
                ? 'text-lime-400'
                : safetyLevel === 'Very High'
                ? 'text-green-500'
                : safetyLevel === 'Highest'
                ? 'text-blue-500'
                : 'text-gray-500' // default color if conditions aren't met
            }
          >
            {safetyLevel}
          </span>
        </p>
        {special ? (
          <div className="mb-4">
            <p className="text-sm mb-2 truncate">
              Select annual/monthly subscription below
            </p>
            <select
              value={planType}
              onChange={handlePlanChange}
              className="w-full text-2xl font-bold bg-gray-700 text-white rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="annual">
                {prices.annual.amount}
                {prices.annual.perUnit}
              </option>
              <option value="monthly">
                {prices.monthly.amount}
                {prices.monthly.perUnit}
              </option>
            </select>
          </div>
        ) : (
          <>
            <p className="text-4xl font-bold truncate">
            {price}
            <span className="text-lg">{perUnit}</span>
            </p>

            <p className="text-sm mt-2 mb-1">
            Enhance Protection w/{" "}
            {isSmallScreen ? ( // checks if screen width is small
                <a href="#bottom" className="text-blue-400 underline">
                Survival Edge
                </a>
            ) : (
                "Survival Edge"
            )}
            </p>

            <div className="mb-1">
            <select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                className="w-full text-md bg-gray-700 text-white rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
                {subscriptionOptions.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
                ))}
            </select>
            </div>
          </>
        )}

        <hr className="my-4 border-t-1 border-gray-200" />
        <ul className="list-disc list-outside ml-4 mb-6 space-y-2">
          {features.map((feature, index) => (
            <li
              key={index}
              className={
                special
                  ? 'flex items-start text-gray-200'
                  : 'flex items-start text-gray-300'
              }
            >
              <span
                className={
                  special ? 'text-gray-200 mr-4' : 'text-gray-300 mr-4'
                }
              >
                •
              </span>
              <span className={special ? 'line-clamp-1' : 'line-clamp-2'}>
                {feature}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {/* Button at the bottom */}
      <div className="flex justify-center mt-6">
        <button
          onClick={handleBuyNowClick}
          className={`rounded px-4 py-2 font-bold text-white transition duration-200 ${
            special
              ? 'bg-gray-900 hover:bg-gray-800' 
              : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default PricingCard;

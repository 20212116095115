// src/pages/PricingPage.js
import React from 'react';
import HeroSection from '../components/HeroSection';
import PricingSection from '../components/PricingSection';
import background from '../assets/images/background5.png';
import SurveyModal from '../components/SurveyModal';
import ExitIntentDetector from '../components/ExitIntentDetector';
import useExitSurveyModal from '../components/useExitSurveyModal';
import { isMobileDevice } from '../utils/deviceUtils';

const PricingPage = () => {

    const {
        isModalOpen,
        setIsModalOpen,
        modalHeadline,
        handleExitIntent,
        hasModalBeenShown,
      } = useExitSurveyModal({ dwellTime: 45000 });
    
      const handleModalClose = () => {
        setIsModalOpen(false);
      };
    
      const handleSurveySubmit = async (response) => {
        // Generate a unique responseId
        const responseId = `response-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    
        // Include the page path
        const data = {
          responseId,
          response,
          timestamp: new Date().toISOString(),
          page: window.location.pathname,
          pricingPage: true, // Set to true since this is the pricing page
        };
    
        // Send data to your backend API
        try {
          await fetch('/api/submit-exit-survey', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          });
        } catch (error) {
          console.error('Error submitting survey response:', error);
        }
      };

      const pricingPageQuestion = 'What do you think of our plan options?';

        const pricingPageOptions = [
        { value: "I don't think the risk is high enough to buy a plan", label: "I don't think the risk is high enough to buy a plan" },
        { value: "Plans look interesting, I might buy later.", label: "Plans look interesting, I might buy later." },
        { value: 'The pricing is too high.', label: 'The pricing is too high.' },
        { value: 'I need more information about the plans.', label: 'I need more information about the plans.' },
        { value: "I'm not ready to make a purchase.", label: "I'm not ready to make a purchase."},
        { value: 'other', label: 'Other:' },
        ];

  return (
    <div 
      className="min-h-screen bg-cover bg-center" 
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
              {!isMobileDevice() && !hasModalBeenShown && (
        <ExitIntentDetector onExitIntent={handleExitIntent} />
      )}
      <SurveyModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleSurveySubmit}
        headline={modalHeadline}
        question={pricingPageQuestion}
        options={pricingPageOptions}
      />
      {/* Overlay for slight transparency */}
      <div className="min-h-screen bg-gray-500 bg-opacity-80">
        
        {/* Hero Section */}
        <HeroSection />
        
        {/* Pricing Section */}
        <PricingSection />

      </div>
    </div>
  );
};

export default PricingPage;

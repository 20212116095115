import React from 'react';
import background from '../assets/images/background4.png';
import infographic from '../assets/images/InfoGraphicWhyUs.png';
import preattackicon from '../assets/images/PreEventPlanIcon.png';
import suppliesicon from '../assets/images/EmSuppliesIcon.png';
import sheltericon from '../assets/images/ShelterStratIcon.png';
import postattackicon from '../assets/images/PostAttackIcon.png';
import { Link, useLocation } from 'react-router-dom';

const WhyUsPage = () => {
  return (
    <div className="min-h-screen bg-cover bg-center relative" style={{ backgroundImage: `url(${background})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative container mx-auto px-4 py-20 text-center text-white">
        <h1 className="text-5xl font-bold mb-6 mt-20">Why Choose Stay Safe Planning?</h1>
        <p className="text-2xl mb-8">We make survival easy so you can be confident when it matters most.</p>
      </div>
      <div className="relative w-full px-10 py-20 text-white bg-gray-400 bg-opacity-20 p-10">
        <div className="flex flex-col md:flex-row items-center md:items-start">
          <div className="w-full md:w-1/2 mb-10 md:mb-0">
            <h2 className="text-4xl font-bold mb-6">Preparedness Tailored to Urban Realities</h2>
            <p className="mb-4">Unlike general survival guides, Our customized plan is specifically designed for the unique challenges of nuclear fallout avoidance and survival.</p>
            <p className="mb-4">With your plan, you’ll find actionable insights on identifying safe shelter zones, navigating crowded areas, and managing limited access to essentials like water and power.</p>
            <ul className="list-disc list-inside mb-4">
              <li>Strategies for sheltering in urban structures, like concrete buildings and basements.</li>
              <li>Guidelines for navigating city landscapes safely during an attack.</li>
              <li>Approaches to secure essential resources in areas with limited availability.</li>
            </ul>
                        {/* "Learn More" Button (Only visible on small screens) */}
        <p className="w-full flex justify-center mt-10 ">
        <Link to="/pricing">
          <button className="bg-ctaorange-100 text-white px-8 py-4 rounded-lg hover:bg-orange-700 transition">
            Explore Plans
          </button>
        </Link>
      </p>
          </div>
            {/* Infographic (Only visible on medium screens and up) */}
          <div className="w-full md:w-1/2 flex items-center justify-center hidden md:flex">
            <img src={infographic} alt="Infographic" className="w-full max-w-md h-auto" />
          </div>

        </div>
      </div>
      <div className="relative w-full px-10 py-20 text-white bg-gray-800 bg-opacity-80 p-10 rounded-lg mt-10">
        <h2 className="text-4xl font-bold mb-6">Everything You Need, In One Place</h2>
        <p className="mb-4">Each of our plans covers every stage of preparedness, from planning and equipping your shelter to post-attack survival tactics. Whether you’re concerned about immediate responses or long-term resilience, the guides step-by-step instructions are clear and easy to follow—even in high-stress situations.</p>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-10">
          <div className="text-center">
            <img src={preattackicon} alt="Pre-Attack Planning" className="mx-auto mb-4 h-12 drop-shadow-md" />
            <p>Pre-Attack Planning</p>
          </div>
          <div className="text-center">
            <img src={suppliesicon} alt="Emergency Supplies" className="mx-auto mb-4 h-12" />
            <p>Emergency Supplies</p>
          </div>
          <div className="text-center">
            <img src={sheltericon} alt="Shelter Strategy" className="mx-auto mb-4 h-12" />
            <p>Shelter Strategy</p>
          </div>
          <div className="text-center">
            <img src={postattackicon} alt="Post-Attack Survival" className="mx-auto mb-4 h-12" />
            <p>Post-Attack Survival</p>
          </div>
        </div>
      </div>
      <div className="relative w-full px-10 py-20 text-white bg-gray-400 bg-opacity-20 p-10  mt-10">
        <h2 className="text-4xl font-bold mb-6">Prepared for Today’s Threats—and Tomorrow’s</h2>
        <p className="mb-4">In an era of evolving threats and urban challenges, Our plans stay relevant by offering you free updates to your purchased guide every time a new version is released. As the world changes, we change with it. You can feel relaxed knowing that you will always have exactly what you need to keep yourself and your family safe.</p>
        <ul className="list-disc list-inside mb-4">
          <li>Current, research-based strategies specific to survival against current threats.</li>
          <li>Updates emailed to you as soon as new versions are released.</li>
          <li>Dedicated team committed to staying ahead of the curve on new products and strategies.</li>
        </ul>
      </div>
      <div className="relative container mx-auto px-4 py-20 text-center text-white">
        <h2 className="text-4xl font-bold mb-6">You can do this.</h2>
        <Link to="/pricing">
        <button className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition">Get Started
        </button>
      </Link>
        
      </div>
    </div>
  );
};

export default WhyUsPage;

// src/components/useExitSurveyModal.js
import { useState, useEffect } from 'react';
import { isMobileDevice } from '../utils/deviceUtils';

const useExitSurveyModal = (options = {}) => {
    const {
      dwellTime = 20000, // Default to 20 seconds if not specified
    } = options;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeadline, setModalHeadline] = useState('');
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenExitModal');
    if (hasSeenModal) {
      setHasModalBeenShown(true);
    } else {
      if (isMobileDevice()) {
        // Mobile device: show modal after 20 seconds
        const timer = setTimeout(() => {
          setModalHeadline('Sorry to interrupt, could you share some quick feedback? (all responses are anonymous)');
          setIsModalOpen(true);
          setHasModalBeenShown(true);
          localStorage.setItem('hasSeenExitModal', 'true');
        }, dwellTime);

        return () => clearTimeout(timer);
      }
    }
  }, [dwellTime]);

  const handleExitIntent = () => {
    if (!hasModalBeenShown) {
      setModalHeadline('Quick question before you go');
      setIsModalOpen(true);
      setHasModalBeenShown(true);
      localStorage.setItem('hasSeenExitModal', 'true');
    }
  };

  return {
    isModalOpen,
    setIsModalOpen,
    modalHeadline,
    handleExitIntent,
    hasModalBeenShown,
  };
};

export default useExitSurveyModal;

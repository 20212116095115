// src/pages/HomePage.js
import React, { useState, useEffect } from 'react';
import background from '../assets/images/background.png';
import worldMapImage from '../assets/images/GradientMap.png';
import arrow1 from '../assets/images/Arrow1.png';
import arrow2 from '../assets/images/Arrow_2.png';
import arrow3 from '../assets/images/Arrow_3.png';
import phone from '../assets/images/Phone_Alert_Icon.png';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SurveyModal from '../components/SurveyModal';
import ExitIntentDetector from '../components/ExitIntentDetector';
import useExitSurveyModal from '../components/useExitSurveyModal';
import { isMobileDevice } from '../utils/deviceUtils';

const Homepage = () => {
      // Download sample PDF function
  const downloadSample = () => {
    const link = document.createElement('a');
    link.href = '/MapDoc7Demo.pdf';
    link.download = 'MapDoc7Demo.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const {
    isModalOpen,
    setIsModalOpen,
    modalHeadline,
    handleExitIntent,
    hasModalBeenShown,
  } = useExitSurveyModal({ dwellTime: 75000 });

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSurveySubmit = async (response) => {
    // Generate a unique responseId
    const responseId = `response-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

    // Include the page path
    const data = {
      responseId,
      response,
      timestamp: new Date().toISOString(),
      page: window.location.pathname,
      pricingPage: false, // Set to false since this is the homepage
    };

    // Send data to your backend API
    try {
      await fetch('/api/submit-exit-survey', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error('Error submitting survey response:', error);
    }
  };

    const homepageQuestion = 'Is there anything we could do to improve your experience?';

    const homepageOptions = [
    { value: "I'm just curious/not ready to buy.", label: "I'm just curious/not ready to buy." },
    { value: "Things look interesting, I might buy later.", label: "Things look interesting, I might buy later." },
    { value: "I didn't find what I was looking for.", label: "I didn't find what I was looking for." },
    { value: "I don't know if this is useful.", label: "I don't know if this is useful." },
    { value: 'Too much to read.', label: 'Too much to read.' },
    { value: 'Too expensive.', label: 'Too expensive.' },
    { value: 'other', label: 'Other:' },
    ];

    return (
        <div>
        {/* Include ExitIntentDetector only on desktop devices */}
        {!isMobileDevice() && !hasModalBeenShown && (
          <ExitIntentDetector onExitIntent={handleExitIntent} />
        )}
        <SurveyModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleSurveySubmit}
          headline={modalHeadline}
          question={homepageQuestion}
          options={homepageOptions}
        /> 
      <div className="min-h-screen bg-cover bg-center relative" style={{ backgroundImage: `url(${background})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-4 py-20 text-center text-white">
          <h1 className="text-5xl font-bold mb-6 mt-10">Everyday protection, for everyday people.</h1>
          <p className="mb-8 mx-20 max-w-screen-lg mx-auto">In the event of a nuclear conflict having no plan to react will likely result in severe injury or death. Our guides are designed to get you prepared to act for as low a cost as possible. Waiting for the next escalation is too late to be ready. Our customized and comprehensive plans will save you from wasting tons of money on equipment you don’t need and from wasting hundreds of hours on doing your own research. Save time, save money, be ready – it’s a no brainer.  Check out our sample guide or take a look at our plan levels to see what works best for you. Get a plan for as low as $39.  </p>
          <Link to="/pricing">
          <button className="bg-ctaorange-100 text-white px-8 py-4 rounded-lg hover:bg-orange-700 transition">Explore Plans
           </button>
         </Link>
        </div>
        <div className="relative w-full mx-auto px-4 py-20 text-white bg-gray-400 bg-opacity-20 mb-10">
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="w-full md:w-1/2 mb-10 md:mb-0">
              <img src={worldMapImage} alt="World map" className="w-full h-auto" />
            </div>
            <div className="w-full md:w-1/2 px-4">
              <h2 className="text-4xl font-bold mb-6">Failing to plan is planning to fail.</h2>
              <p className="mb-4">Our guides focus on keeping you safe during a nuclear conflict through planning and education. We provide plans that are to the point and comprehensive, so you get what you need and nothing more. We want to make sure you can: </p>
            <p>
            <ul className="list-disc list-inside mb-4">
                <p></p>
              <li>Recognize the warning signs that an emergency is coming.</li>
              <li>Understand what you need to do before you are out of time.</li>
              <li>Avoid relying on flimsy one-page advice from government agencies.</li>
              <li>Know how you will react in the moment.</li>
              <li>Be prepared for what comes after - this isn't an apocolypse movie, life will march on.</li>
            </ul>
            </p>
            <p className="mb-4">There are three key aspects to our strategy: customized local maps, right-sized guides to fit your needs and goals, and Survival Edge (SMS messages that provide you with useful guidance throughout an entire emergency). All of our plans come with a free customized local map, supply lists to fit your budget, and our best advice for improving your survivability over 7, 49, or 336 hours (14 days). We hope you never need this info, but for such a small cost, it just makes sense to have a plan in place if the worst comes to pass. </p>
            </div>
          </div>
        </div>

        <div className="relative container mx-auto px-4 py-20 text-white grid grid-cols-1 md:grid-cols-4 gap-8">
        <div className="text-center">
          <img src={arrow1} alt="Arrow Icon" className="mx-auto mb-4 w-12 h-12" />
          <i className="$1"></i>
          <p>Pinpoint recommendations make sure you get exactly where you need to be when you need to be there.</p>
        </div>
        <div className="text-center">
          <img src={phone} alt="Phone Icon" className="mx-auto mb-4 w-12 h-12" />
          <i className="$1"></i>
          <p>Our first-of-its-kind Survival Edge subscription delivers immediate critical instructions so you don't waste a single precious moment. Check our <a href="/faq" className="text-blue-400 underline">FAQ</a> to learn more.</p>
        </div>
        <div className="text-center">
          <img src={arrow2} alt="Arrow Icon" className="mx-auto mb-4 w-12 h-12" />
          <i className="$1"></i>
          <p>Get clear step-by-step guidance on how to plan, how to evaluate your options, and what you need to have with you.</p>
        </div>
        <div className="text-center">
          <img src={arrow3} alt="Arrow Icon" className="mx-auto mb-4 w-12 h-12" />
          <i className="$1"></i>
          <p>You're joining an elite minority by having a reliable and well-rehearsed plan in place to protect your family.</p>
        </div>
        <div className="absolute left-1/4 top-0 transform h-full w-px bg-gray-300 hidden md:block"></div>
        <div className="absolute left-2/4 top-0 transform h-full w-px bg-gray-300 hidden md:block"></div>
        <div className="absolute left-3/4 top-0 transform h-full w-px bg-gray-300 hidden md:block"></div>
      </div>
      <div className="relative container mx-auto px-4 py-20 text-center text-white">
        <h2 className="text-4xl font-bold mb-6">Not yet convinced?</h2>
        <p className="mb-8">Download a sample to get a sneak peak of exactly what you can expect to receive at purchase.</p>
        <button 
            onClick={() => {
                ReactGA.event({
                category: 'Download',
                action: 'Homepage_Download',
                label: 'Sample PDF from Homepage',
                });
                downloadSample(); // Your existing function to download the file
            }}
            className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition">Download</button>
      </div>
    </div>
    </div>
  );
};
  
  export default Homepage;
  

//pages/FAQPage.js
import React from 'react';
import background from '../assets/images/Background3.png';

const FAQPage = () => {
    return (
      <div
        className="min-h-screen bg-cover bg-center relative text-white"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-80"></div>
        <div className="relative flex items-center justify-center min-h-600">
          <div className="bg-gray-800 bg-opacity-60 p-8 rounded-3xl shadow-lg w-full max-w-2xl mt-20">
          <h1 className="text-3xl font-bold mb-6 text-center">We encourage questions - hopefully the answer you're looking for is below.</h1>
          <div className="space-y-4 ">
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">What is your return policy?</summary>
                <p className="mt-2">For our digital products, such as survival plans and customized maps - we do not offer returns. For our Survival Edge subscription if you've chosen an annual plan, we offer a full refund within 30 days of purchase. For an annual plan we will offer a prorated refund after 30 days. For a monthly plan, you can cancel anytime but we will not offer a prorated refund for the current month, we simply will not charge for any following months.</p>
              </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">Do you offer international shipping?</summary>
                <p className="mt-2">Right now we are only offering custom maps and Survival Edge within the United States. If you are interested in only the plans and recommendations, those are broadly applicable and provided via digital download - so accessible to all. None of our products require shipping.</p>
              </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">What makes this unique compared to other survival guides?</summary>
                <p className="mt-2">This guide is tailored specifically toward surviving in the immediate aftermath of a nuclear emergency. This is an extremely unique circumstance. The Stay Safe Planning team has spent hundreds of hours pouring over products and research related to the unique challenges you will face. We then put together these guides so they will be super simple for anyone to quickly read and understand. We stripped out all the nonsense so you can get to just the info you need.</p>
              </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">What is the Survival Edge subscription for?</summary>
                <p className="mt-2">In the first 15 minutes of reacting during a nuclear attack you need to gather a lot of information. At this same time networks may be interrupted or over loaded. Survival Edge ensures you get exactly the information you need so you can react without hesitation. This will save you significant time in the most critical moments. We take a step further as well and continue to support you both prior to an event and after that first 24 hours has passed. Before an event we send quarterly prompts which you can use to kick off your mock walk through, ensuring you understand your supplies and are ready to take action when the time really comes. After the first 24 hours we continue to send you critical information that you will need to make decisions on your next moves. This is potentially life saving support. </p>
              </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">How much prior knowledge is needed to benefit from this?</summary>
                <p className="mt-2">Zero. This is made so anyone can benefit from it immediately.</p>
              </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">Does the guide include practical, step-by-step guidance that non-military individuals can follow?</summary>
                <p className="mt-2">100%, exactly this. You are guided through every critical moment step-by-step with crystal clear instruction accessible to any person, military or not.</p>
              </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">Does this guide offer specific recommendations for supplies or will I still need to research that?</summary>
                <p className="mt-2">Our Bare Minimum plan does NOT provide specific product recommendations mainly because the supplies are very simplistic, so we offer the type of thing to buy not the brand/model. Enhanced Survival and Total Protection offer specific product recommendations where we found that to be beneficial.</p>
                </details>
            </div>
            <div>
              <details className="py-4">
                <summary className="font-semibold cursor-pointer">How comprehensive is this plan regarding sheltering, evacuation, and tactics during an emergency?</summary>
                <p className="mt-2">We are extremely comprehensive, covering every scenario you should encounter - but, we are not comprehensive in the sense that this reads like a textbook. This is quick hit information without all the history and background. </p>
                </details>
            </div>
          </div>
          <div className="mt-8 text-center flex items-center justify-center space-x-4">
            <p>Have another question? Email us @ cust.service@staysafeplanning.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;

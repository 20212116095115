// src/components/Navbar.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/StaySafeLogo.png';
import ReactGA from 'react-ga4';

const Navbar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to determine active link style
  const getLinkStyle = (path) => (
    location.pathname === path ? 'text-blue-400' : 'text-white'
  );

  // Download sample PDF function
  const downloadSample = () => {
    const link = document.createElement('a');
    link.href = '/MapDoc7Demo.pdf';
    link.download = 'StaySafeDemoGuide.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <nav className="fixed left-0 top-0 z-10 w-full bg-gray-800 bg-opacity-90 md:bg-opacity-60 py-4 px-2">
      <div className="container mx-auto flex items-center justify-between">
        {/* Logo with link to home page */}
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Company Logo" className="h-6 sm:h-6 md:h-8 lg:h-8 mr-2" />
          <span className="sr-only">Home</span>
        </Link>

        {/* Desktop links */}
        <div className="hidden space-x-8 md:flex">
          <Link to="/why-us" className={`${getLinkStyle('/why-us')} hover:text-blue-400`}>Why Us</Link>
          <Link to="/faq" className={`${getLinkStyle('/faq')} hover:text-blue-400`}>FAQs</Link>
          <Link to="/pricing" className={`${getLinkStyle('/pricing')} hover:text-blue-400`}>Pricing</Link>
          <a
            href="/TermsOfUse.pdf"
            download="StaySafeTerms.pdf"
            className="text-gray-400 hover:text-blue-400"
        >
            Terms
        </a>
        <a
            href="/PrivacyPolicy.pdf"
            download="StaySafePrivacy.pdf"
            className="text-gray-400 hover:text-blue-400"
        >
            Privacy
        </a>
        </div>

    {/* Buttons for larger screens */}
    <div className="hidden md:flex md:items-center space-x-4">
      <Link to="/login">
        <button className="rounded border border-blue-500 text-white bg-transparent px-4 py-2 transition duration-200 hover:bg-blue-500">
          Log In
        </button>
      </Link>
      <button
        onClick={downloadSample}
        className="rounded bg-blue-500 px-4 py-2 text-white transition duration-200 hover:bg-blue-700"
      >
        View Sample
      </button>
    </div>

    {/* Mobile View Sample button and Menu Toggle */}
    <div className="md:hidden flex items-center space-x-2">
    <button
        onClick={() => {
            ReactGA.event({
            category: 'Download',
            action: 'Navbar_Download',
            label: 'Sample PDF',
            });
            downloadSample(); 
        }}
        className="rounded bg-blue-500 px-4 py-2 text-white transition duration-200 hover:bg-blue-700"
        >
        View Sample
    </button>
      <button
        onClick={toggleMenu}
        className="text-white focus:outline-none"
        aria-label="Toggle menu"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
    </div>
  </div>

        {/* Mobile Links */}
        {isMenuOpen && (
        <div className="md:hidden bg-gray-800 bg-opacity-90 py-4 rounded-lg mt-2 shadow-lg">
            <div className="flex flex-col items-center space-y-3">
            <Link
                to="/why-us"
                className={`${getLinkStyle('/why-us')} hover:text-blue-400 transition duration-200 transform hover:translate-x-1`}
                onClick={toggleMenu}
            >
                Why Us
            </Link>
            <Link
                to="/faq"
                className={`${getLinkStyle('/faq')} hover:text-blue-400 transition duration-200 transform hover:translate-x-1`}
                onClick={toggleMenu}
            >
                FAQs
            </Link>
            <Link
                to="/pricing"
                className={`${getLinkStyle('/pricing')} hover:text-blue-400 transition duration-200 transform hover:translate-x-1`}
                onClick={toggleMenu}
            >
                Pricing
            </Link>
            <Link
                to="/login"
                className={`${getLinkStyle('/login')} hover:text-blue-400 transition duration-200 transform hover:translate-x-1`}
                onClick={toggleMenu}
            >
                Login
            </Link>
            <a
                href="/TermsOfUse.pdf"
                download="StaySafeTerms.pdf"
                className="text-gray-400 hover:text-blue-400"
            >
                Terms
            </a>
            <a
                href="/PrivacyPolicy.pdf"
                download="StaySafePrivacy.pdf"
                className="text-gray-400 hover:text-blue-400"
            >
                Privacy
            </a>
            </div>
        </div>
        )}      
    </nav>
  );
};

export default Navbar;

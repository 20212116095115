// src/pages/OrderDetailsPage.js
import React from 'react';
import Navbar from '../components/Navbar';

const OrderDetailsPage = () => (
  <div>
    <Navbar />
    <div style={{ textAlign: 'center', paddingTop: '80px' }}>
    <h1>OrderDetails Page</h1>
    </div>
  </div>
);

export default OrderDetailsPage;

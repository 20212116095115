// src/components/HeroSection.js
import React from 'react';

const HeroSection = () => {
  return (
    <section className="flex items-center justify-center px-5 pt-40 pb-10 sm:pb-20 md:pb-25 text-center text-white">
      <div>
        <h1 className="text-5xl font-bold leading-tight md:text-6xl">
          Smart choice. <p></p>Grab your guide below.
        </h1>
        <p className="mx-auto mt-3 max-w-2xl text-lg md:text-xl">
          Simple plans, no wasted resources. Don't waste money buying things you don't need, don't waste time on research we've already done. Just pick your plan, and get started protecting your family. You can start small and build from there. Make sure to check out Survival Edge, it's pretty cool.
        </p>
      </div>
    </section>
  );
};

export default HeroSection;

// src/components/ExitIntentDetector.js
import React, { useEffect } from 'react';

const ExitIntentDetector = ({ onExitIntent }) => {
  useEffect(() => {
    const handleMouseLeave = (e) => {
      // Check if the mouse leaves the window from the top
      if (e.clientY <= 0) {
        onExitIntent();
      }
    };

    // Attach the mouseleave event listener
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      // Clean up the event listener
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [onExitIntent]);

  return null; // This component doesn't render anything
};

export default ExitIntentDetector;

// src/pages/Success.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Success = () => {
    const [sessionData, setSessionData] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();
    const sessionId = new URLSearchParams(location.search).get('session_id');

    useEffect(() => {
        const fetchSessionData = async () => {
          try {
            const response = await fetch(`/api/fetch-session?session_id=${sessionId}`);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setSessionData(data);
          } catch (err) {
            setError("Failed to fetch session data. Please try again later.");
            console.error("Error fetching session data:", err);
          }
        };
    
        if (sessionId) {
          fetchSessionData();
        }
      }, [sessionId]);
    
      if (error) {
        return <div>{error}</div>;
      }
    
      if (!sessionData) {
        return <div>Loading...</div>;
      }

  // Function to share the product details on social media
  const shareProduct = () => {
    const shareText = `I just got my guide to ensure my family is safe in a nuclear emergency. If you don't have a plan for your family, you should check it out here: ${window.location.origin}/product-url`;
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`;
    window.open(shareUrl, '_blank');
  };

  const { id, amount_total, customer_details, payment_status } = sessionData;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 bg-opacity-90 p-8">
      <div className="bg-gray-800 bg-opacity-80 rounded-3xl shadow-lg p-8 max-w-lg w-full text-center text-white">
        <h1 className="text-4xl font-bold text-green-400 mb-6">Thank you for your purchase!</h1>
        
        <p className="text-lg break-words">Order ID: <span className="font-semibold">{id}</span></p>
        <p className="text-lg">Amount Paid: <span className="font-semibold">${(amount_total / 100).toFixed(2)}</span></p>
        <p className="text-lg">Payment Status: <span className="font-semibold">{payment_status}</span></p>
        
        {customer_details && (
          <div className="mt-6 text-left">
            <p className="text-gray-300"><strong>Name:</strong> {customer_details.name}</p>
            <p className="text-gray-300"><strong>Email:</strong> {customer_details.email}</p>
            {customer_details.address && (
              <p className="text-gray-300">
                <strong>Address:</strong> {`${customer_details.address.line1}, ${customer_details.address.city}, ${customer_details.address.state} ${customer_details.address.postal_code}, ${customer_details.address.country}`}
              </p>
            )}
            <p className="text-gray-300"><strong>Phone:</strong> {customer_details.phone}</p>
          </div>
        )}

        {/* Order Message */}
        <div className="mt-5 text-gray-300">
            If you purchased one of our guides it will be sent to the email address above. If you enrolled in Survival Edge you will receive a confirmation SMS at the phone number above. If you have not received your email or SMS within 24 hours, please reach out to us @ cust.service@staysafeplanning.com and we will look into it immediately.
        </div>
  
        {/* Social Sharing Button */}
        <div className="mt-8">
          <button 
            onClick={shareProduct} 
            className="bg-blue-500 px-6 py-3 rounded-full text-white font-bold hover:bg-blue-600 transition duration-200"
          >
            Share with Friends
          </button>
        </div>
  
        {/* Link to go back to the homepage */}
        <div className="mt-6">
          <a href="/" className="text-blue-400 hover:underline">Go back to Home</a>
        </div>
      </div>
    </div>
  );  
};

export default Success;
